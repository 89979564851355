<template>
	<div class="sycm-page">
		<div class="sycm-section sycm-filter">
			<div class="filter-main">{{$t('sycm.common.statisticalTime')}} {{ parseInt(startTime/1000) | parseTime('{y}-{m}-{d}')}}~{{ parseInt(endTime/1000) | parseTime('{y}-{m}-{d}')}}</div>
			<ul class="filter-bar">
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 1}" @click="handleChangeTime(1)">1{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 2}" @click="handleChangeTime(2)">7{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 3}" @click="handleChangeTime(3)">30{{$t('sycm.unit.day')}}</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 4}" @click="handleDatePicker(4)">{{$t('sycm.actions.day')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="dayData"
								type="date"
								ref="dayPicker"
								:placeholder="$t('sycm.placeholder.date')"
								:picker-options="pickerOptions"
								@change="changeDatePicker($event,4)">
							</el-date-picker>
						</div>
					</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 5}" @click="handleDatePicker(5)">{{$t('sycm.actions.week')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="weekData"
								type="week"
								ref="weekPicker"
								format="WW"
								:placeholder="$t('sycm.placeholder.week')"
								:picker-options="pickerWeekOptions"
								@change="changeDatePicker($event,5)">
							</el-date-picker>
						</div>
					</div>
				</li>
				<li class="bar-item">
					<div class="filter-btn" :class="{active:dateType == 6}" @click="handleDatePicker(6)">{{$t('sycm.actions.month')}}
						<div style="height: 0px;width: 0;overflow:hidden">
							<el-date-picker
								v-model="monthData"
								type="month"
								ref="monthPicker"
								:placeholder="$t('sycm.placeholder.month')"
								:picker-options="pickerMonthOptions"
								@change="changeDatePicker($event,6)">
							</el-date-picker>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">{{$t('sycm.trade.terminalForm')}}</div>
				<ul class="barbox">
					<!-- <li><div class="item-link"><span>{{$t('sycm.actions.download')}}</span><i class="el-icon-download"></i></div></li> -->
				</ul>
			</div>
			<div class="section-main">
				<div class="sycm-terminal">
					<div class="terminal-box">
						<div class="sycm-chart">
							<chart id="pieChart" v-if="chartOption" :chart-data="chartOption" height="100%" width="100%" />
						</div>
					</div>
					<div class="terminal-main">
						<table style="width: 100%">
							<tr>
								<th align="left">{{$t('sycm.tableHead.terminal')}}</th>
								<th align="right">{{$t('sycm.common.payMoney')}}</th>
								<th align="right">{{$t('sycm.common.payMoney')+$t('sycm.tableHead.proportion')}}</th>
								<th align="right">{{$t('sycm.common.payGoods')}}</th>
								<th align="right">{{$t('sycm.common.payBuyers')}}</th>
								<th align="right">{{$t('sycm.common.payConversion')}}</th>
							</tr>
							<tr v-for="(item,index) in terminalData" :key="index">
								<td align="left">{{item.from}}</td>
								<td align="right">
									<template v-if="parseFloat(item.payment_amount) > 0">{{item.payment_amount | priceFilter()}}</template>
									<template v-else>-</template>
								</td>
								<td align="right">
									<template v-if="parseFloat(item.payment_amount_rate) > 0">{{item.payment_amount_rate | priceFilter()}}%</template>
									<template v-else>-</template>
								</td>
								<td align="right">{{item.payment_goods || ''}}</td>
								<td align="right">{{item.payment_buyers || ''}}</td>
								<td align="right">
									<template v-if="parseFloat(item.payment_rate) > 0">{{item.payment_rate | priceFilter()}}%</template>
									<template v-else>-</template>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div class="sycm-section">
			<div class="section-head">
				<div class="title">{{$t('sycm.trade.cateForm')}}</div>
				<ul class="barbox">
					<li>
						<el-dropdown class="sycm-bar-dropdown" placement="bottom" @command="handleCommand">
							<span class="el-dropdown-link">
								{{ terminalType | terminalFilter($t('sycm.dropdown.terminal')) }}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="(item,index) in $t('sycm.dropdown.terminal')" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</li>
					<!-- <li><div class="item-link"><span>{{$t('sycm.actions.download')}}</span><i class="el-icon-download"></i></div></li> -->
				</ul>
			</div>
			<ul class="cate-tabs">
				<li v-for="(item,index) in cateOption" :key="index" :class="{active:activeTab == item.cate_id}" @click="handleChangeCate(item.cate_id)">
					<div class="name">{{item.category_name}}</div>
					<div class="number">{{$t('sycm.tableHead.proportion')}}：{{item.rate | priceFilter()}}%</div>
				</li>
			</ul>
			<div class="section-main">
				<el-table :data="cateList" @sort-change="handleSortPageChange">
					<el-table-column :label="$t('sycm.tableHead.cate')" prop="from"></el-table-column>
					<el-table-column :label="$t('sycm.common.payMoney')" align="right" prop="payment_amount" sortable>
						<template slot-scope="scope">{{scope.row.payment_amount | priceFilter()}}</template>
					</el-table-column>
					<el-table-column :label="$t('sycm.common.payMoney')+$t('sycm.tableHead.proportion')" align="right" prop="payment_amount_rate" sortable>
						<template slot-scope="scope">{{scope.row.payment_amount_rate | priceFilter()}}%</template>
					</el-table-column>
					<el-table-column :label="$t('sycm.common.payBuyers')" align="right" prop="payment_buyers" sortable></el-table-column>
					<el-table-column :label="$t('sycm.common.payConversion')" align="right" prop="payment_rate" sortable>
						<template slot-scope="scope">{{scope.row.payment_rate | priceFilter()}}%</template>
					</el-table-column>
					<!-- <el-table-column :label="$t('sycm.tableHead.actions')" align="center">
						<template slot-scope="scope">
							<a href="javascript:void(0);" class="item-link" @click="handleTrendShow(scope)">{{$t('sycm.actions.viewTrends')}}</a>
						</template>
					</el-table-column> -->
				</el-table>
			</div>
		</div>
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisibleTrends" width="800px">
			<div class="dialog-chart">
				<sycm-chart id="dialogChart" v-if="dialogChart" :x-data="dialogChart.xData" :s-data="dialogChart.sData" legend-left="0" height="100%" width="100%" />
			</div>
		</el-dialog>
	</div>
</template>

<script>
import Chart from '@/components/echart/pieChart'
import { fetchTradeTerminal, fetchTradeCate, fetchTradeCateList } from "@/api/sycm"
export default {
	components: { Chart },
	filters: {
		terminalFilter(type, options){
			if(type=='') {
				type = 0
			}
			return options[type].label
		}
	},
	data() {
		return {
			terminalType: '',
			dateType: 1,
			dayData: '',
			weekData: '',
			monthData: '',
			pickerOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-3)
					return time.getTime() > Date.now() - 86400000 || time.getTime() < today.getTime() - 86400000
				},
				firstDayOfWeek: 1
			},
			pickerWeekOptions: {
				disabledDate(time) {
					const today = new Date()
					const day = today.getDay()
					today.setDate(today.getDate()-day)
					const end_time = today.getTime()
					today.setDate(today.getDate()-84)
					const start_time = today.getTime()
					return time.getTime() >end_time || time.getTime() < start_time
				},
				firstDayOfWeek: 1
			},
			pickerMonthOptions: {
				disabledDate(time) {
					const today = new Date()
					today.setMonth(today.getMonth()-1)
					const end_time = today.getTime()
					today.setMonth(today.getMonth()-11)
					const start_time = today.getTime()
					return time.getTime() > end_time || time.getTime() < start_time
				}
			},
			startTime: '',
			endTime: '',
			chartOption: null,
			terminalData: {},
			cateOption: [],
			activeTab: 0,
			cateList: [],
			pageTotal: 0,
			pageCurr: 1,
			pageSize: 10,
			pageSort: '',
			pageField: '',
			dialogVisibleTrends: false,
			dialogTitle: '',
			dialogChart: null
		}
	},
	created () {
		this.handleChangeTime()
	},
	methods: {
		async getData(){
			const loading = this.$loading()
			try {
				const params = {
					time_from: parseInt(this.startTime / 1000),
					time_to: parseInt(this.endTime / 1000),
					date_type: this.dateType
				}
				const terminal = await fetchTradeTerminal(params)
				this.terminalData = terminal.data
				this.getChartOption(terminal.data)
				this.getCateTabs()
			} catch (error) {
				console.log(error)
			}
			loading.close()
		},
		getCateTabs() {
			const params = {
				source: this.terminalType,
				time_from: parseInt(this.startTime / 1000),
				time_to: parseInt(this.endTime / 1000),
				date_type: this.dateType
			}
			fetchTradeCate(params).then(response => {
				const { data } = response
				this.cateOption = data
				if(data.length > 0) {
					this.activeTab = data[0].cate_id
					this.getCateList()
				}
			})
		},
		getCateList() {
			const params = {
				source: this.terminalType,
				time_from: parseInt(this.startTime / 1000),
				time_to: parseInt(this.endTime / 1000),
				date_type: this.dateType,
				cate_id: this.activeTab,
				order_type: this.pageSort,
				order_field: this.pageField
			}
			fetchTradeCateList(params).then(response => {
				const { data } = response
				this.cateList = data
				// this.pageTotal = parseInt(data.count)
			})
		},
		handleChangeTime(val) {
			this.dateType = val ? val : 1
			let today = new Date()
			switch(this.dateType) {
				case 1:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-1).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
				case 2:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-7).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
				case 3:
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-30).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime() - 1000
					break;
			}
			this.getData()
		},
		handleDatePicker(type) {
			switch(type) {
				case 4:
					this.$refs.dayPicker.focus()
					break;
				case 5:
					this.$refs.weekPicker.focus()
					break;
				case 6:
					this.$refs.monthPicker.focus()
					break;
			}
		},
		changeDatePicker(val,type) {
			this.dateType = type
			const today = new Date(val)
			console.log(val)
			switch(type) {
				case 4:
					this.monthData = ''
					this.weekData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+1).getTime() - 1000
					break;
				case 5:
					this.dayData = ''
					this.monthData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()-1).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()+6).getTime() - 1000
					break;
				case 6:
					this.dayData = ''
					this.weekData = ''
					this.startTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime()
					this.endTime = new Date(today.getFullYear(),today.getMonth()+1,1).getTime() - 1000
					break;
			}
			this.getData()
		},
		getChartOption(data) {
			const sData = []
			this.chartOption = null
			data.forEach((item,index) => {
				sData.push({
					name: item.from,
					value: parseFloat(item.payment_amount).toFixed(2),
					itemStyle: {
						color: index==1?'#98cd6d':'#6fcac8'
					}
				})
			})
			this.$nextTick(()=>{
				this.chartOption = sData
			})
		},
		handleCommand(command) {
			this.terminalType = command
			this.pageCurr = 1
			this.getCateTabs()
		},
		handleChangeCate(val) {
			this.activeTab = val
			this.pageCurr = 1
			this.getCateList()
		},
		handleSortPageChange(value) {
			if(value.order == "descending") {
				this.pageSort = 'desc'
			} else {
				this.pageSort = 'asc'
			}
			this.pageField = value.prop
			this.pageCurr = 1
			this.getCateList()
		},
		handleTrendShow({row}) {
			this.dialogTitle = this.$t('sycm.actions.viewTrends') + '-' + row.from
			this.dialogChart = null
			const xData = []
			const paymentAmount = []
			const paymentAmountRate = []
			const paymentBuyers = []
			const paymentTransRate = []
			row.list.forEach(item=> {
				xData.push(item.x_values)
				paymentAmount.push(item.payment_amount)
				paymentAmountRate.push(item.payment_amount_rate)
				paymentBuyers.push(item.payment_buyers)
				paymentTransRate.push(item.payment_trans_rate)
			})
			const options = {
				xData: xData,
				sData: [{
					name: '支付金额占比',
					data: paymentAmountRate,
					type: 'line',
					showSymbol: false,
					smooth: true
				},{
					name: '支付金额',
					data: paymentAmount,
					type: 'line',
					showSymbol: false,
					smooth: true
				},{
					name: '支付买家数',
					data: paymentBuyers,
					type: 'line',
					showSymbol: false,
					smooth: true
				},{
					name: '支付转化率',
					data: paymentTransRate,
					type: 'line',
					showSymbol: false,
					smooth: true
				}]
			}
			this.$nextTick(()=>{
				this.dialogChart = options
				this.dialogVisibleTrends = true
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-page {
	.sycm-section {
		margin-bottom: 10px;
		box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
		background-color: #ffffff;

		.section-head {
			display: flex;
			padding: 0 30px;
			line-height: 50px;
			justify-content: space-between;
			border-bottom: 1px solid #e6e6e6;

			.title {
				font-size: 16px;
				font-weight: bold;
				display: inline-block;
				vertical-align: top;
			}

			.head-menu {
				display: inline-block;
				vertical-align: top;
				margin-left: 40px;

				li {
					display: inline-block;
					vertical-align: top;
					font-size: 14px;
					margin: 0 10px;
					line-height: 46px;
					cursor: pointer;
					border-top: 2px solid transparent;
					border-bottom: 2px solid transparent;

					&.active {
						color: $--sycm-primary;
						border-bottom-color: $--sycm-primary;
					}
				}
			}

			.barbox {
				li {
					display: inline-block;
					vertical-align: middle;
					margin-left: 10px;
				}
				.item-link {
					color: $--sycm-primary;
					cursor: pointer;
					display: inline-block;

					span {
						margin-right: 5px;
					}

					&:hover {
						color: $--sycm-hover-1;
					}
				}
			}
		}

		.section-main {
			min-height: 100px;
			padding: 0 30px 30px 30px;
			.el-table {
				font-size: 12px;
				.item-link {
					color: $--sycm-primary;
					&:hover {
						color: $--sycm-hover-1;
					}
				}
			}
		}
	}

	.sycm-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px 30px;

		.filter-main {
			font-size: 12px;
			line-height: 1.5;
			color: #333333;
		}

		.filter-bar {
			display: flex;
			.bar-item {
				margin-left: 10px;
			}
			.filter-btn {
				padding: 7px 15px;
				border-radius: 3px;
				font-size: 12px;
				line-height: 1;
				cursor: pointer;
				border: 1px solid #DCDFE6;
				background-color: #ffffff;
				&:hover {
					color: $--sycm-primary;
					border-color: $--sycm-primary;
				}
				&.active {
					color: #ffffff;
					border-color: $--sycm-primary;
					background-color: $--sycm-primary;
				}
			}
		}
	}
	.sycm-terminal {
		display: flex;
		.terminal-box {
			flex-shrink: 1;
			width: 32%;
			box-sizing: border-box;
			padding: 36px 40px 0;
			.sycm-chart {
				width: 100%;
				height: 250px;
			}
		}
		.terminal-main {
			flex-shrink: 0;
			width: 68%;
			margin-top: 30px;
			table {
				th,td {
					font-size: 12px;
					line-height: 1.5;
					padding: 25px 5px;
					border-bottom: 2px solid #f0f0f0;
				}
				td {
					color: #555555;
				}
			}
		}
	}
	.cate-tabs {
		display: flex;
		flex-flow: wrap;
		li {
			width: 12.5%;
			flex-shrink: 0;
			text-align: center;
			font-size: 12px;
			line-height: 1.5;
			padding: 15px 10px;
			box-sizing: border-box;
			cursor: pointer;
			background-color: #f0f0f0;
			.name {
				font-size: 16px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&.active {
				background-color: #ffffff;
			}
		}
	}
	.dialog-chart {
		width: 752px;
		height: 300px
	}
}
</style>