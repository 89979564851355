import request from '@/utils/request'
import { param2Obj } from '@/utils'

export function fetchHomeStatistics(data) {
  return request({
    url: '/business/index/store/data/real/time',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchPayMoney(data) {
  return request({
    url: '/business/index/order/price/real/time',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchRefundRate(data) {
  return request({
    url: '/business/index/order/refund/rate',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchRefundMoney(data) {
  return request({
    url: '/business/index/order/refund/price',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchRefundNumber(data) {
  return request({
    url: '/business/index/order/refund/numbers',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchIpollOverview(data) {
  return request({
    url: '/business/store/data/real/time/overview',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchIpollTrend(data) {
  return request({
    url: '/business/store/data/real/time/trend',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchIpollSource(data) {
  return request({
    url: '/business/store/data/real/time/source',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchIpollRank(data) {
  return request({
    url: '/business/real/time/goods/list',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchIpollToPay(data) {
  return request({
    url: '/business/real/time/order/list',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchFlowVisitStore(data) {
  return request({
    url: '/business/analysis/store/visitors',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchFlowVisitGoods(data) {
  return request({
    url: '/business/analysis/goods/views',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchFlowVisitBuyers(data) {
  return request({
    url: '/business/analysis/store/buyers',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchFlowVisitOverview(data) {
  return request({
    url: '/business/analysis/store/overview',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchFlowVisitChart(data) {
  return request({
    url: '/business/analysis/store/detail/overview',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchFlowPeriod(data) {
  return request({
    url: '/business/analysis/store/detail/visitors',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchFlowRegion(data) {
  return request({
    url: '/business/analysis/region/detail/visitors',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchFlowBehavior(data) {
  return request({
    url: '/business/analysis/store/behavioral',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchGoodsMovablePin(data) {
  return request({
    url: '/business/category/goods/sales/price',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchGoodsCollectAddBuy(data) {
  return request({
    url: '/business/category/goods/collect/cart/total',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchGoodsVisitors(data) {
  return request({
    url: '/business/category/goods/views/total',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchCateOverview(data) {
  return request({
    url: '/business/category/goods/overs/total',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchCateChart(data) {
  return request({
    url: '/business/category/goods/overs/item/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchCateGoods(data) {
  return request({
    url: '/business/category/goods/ranking',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchTradeOverview(data) {
  return request({
    url: '/business/transaction/overview',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchTradeTerminal(data) {
  return request({
    url: '/business/transaction/terminal/composition',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchTradeCate(data) {
  return request({
    url: '/business/transaction/category/compositionTitle',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchTradeCateList(data) {
  return request({
    url: '/business/transaction/category/compositionDetial',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLivesOverview(data) {
  return request({
    url: '/business/live/overview',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLivesCrowd(data) {
  return request({
    url: '/business/live/population/composition',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLivesMarketOverview(data) {
  return request({
    url: '/business/live/population/transactionPrice',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLivesMarketInfo(data) {
  return request({
    url: '/business/live/population/detail/transactionPrice',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLivesMarketList(data) {
  return request({
    url: '/business/live/population/page/transactionPrice',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLivesMarketTimes(data) {
  return request({
    url: '/business/live/population/live/list/select',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLivesMarketGoods(data) {
  return request({
    url: '/business/live/population/goods/pageLiveGoodsDetail',
    method: 'post',
    data: param2Obj(data)
  })
}
