<template>
    <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts'
import resize from './mixins/resize'

export default {
    mixins: [resize],
    props: {
        chartData: {
            type: Array,
            default: function () {
                return []
            }
        },
        className: {
            type: String,
            default: 'chart'
        },
        id: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '200px'
        },
        height: {
            type: String,
            default: '200px'
        }
    },
    data() {
        return {
            chart: null
        }
    },
    mounted() {
        this.initChart()
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(document.getElementById(this.id))

            this.chart.setOption({
                grid: {
                    top: 0
                },
                legend: {
                    bottom: '1%',
                    itemWidth: 10,
                    itemHeight: 10,
                    icon: 'rect'
                },
                series: [{
                    type: 'pie',
                    radius: '85%',
                    center: ['50%','46%'],
                    data: this.chartData,
                    label: {
                        show: false
                    }
                }]
            })
        }
    }
}
</script>
